body {
    background: radial-gradient(rgba(114,75,148,1) 0%, rgba(35,12,70,1) 70%) no-repeat center rgba(35,12,70,1);
    background-repeat: no-repeat;
    background-size: contain;
    font-family: Helvetica, sans-serif;
    color: #FFFFFF;
    font-size: 16px;
}

div.logo {
    background-image: url('Assets/F&G Logo_Tagline_Full_Color_for Purple Bkgrd_RGB@2x.png');
    background-repeat: no-repeat;
    background-size: contain;
    height: 100px;
    width: 100%;
}

@media(min-width: 768px) {
    body {
        background: url('Assets/1920 ampersand.png') no-repeat bottom, radial-gradient(rgba(114,75,148,1) 0%, rgba(35,12,70,1) 70%) no-repeat center rgba(35,12,70,1);
        background-size: contain;
        background-repeat: no-repeat;
    }

    div.logo {
        height: 155px;
    }

}
@media(min-width: 1200px) {
    body {
        background: url('Assets/1920 ampersand.png') no-repeat left, radial-gradient(rgba(114,75,148,1) 0%, rgba(35,12,70,1) 70%) no-repeat center rgba(35,12,70,1);
        background-size: contain;
    }

    div.logo {
        height: 210px;
    }
}

div.container{
    margin-top: 5vw;
    max-width: 85% !important;
}

.form-control {
    border-radius: 0 !important;
}
.btn.btn-primary {
    background-color: #FEC10D;
    color: #582C83;
    text-transform: uppercase;
    padding: 10px 35px;
    font-weight: bold;
    font-size: 18px;
}
#termsAndConditions {
    background-color: rgba(255,255,255, 0.1);
    overflow: auto;
    height: 530px;
    padding: 15px;
    margin: 15px 0;
}

/* Scrollbar styles */
    #termsAndConditions::-webkit-scrollbar {
        width: 12px;
        height: 12px;
    }

    #termsAndConditions::-webkit-scrollbar-track {
        border: 1px solid #636466;
        border-radius: 10px;
    }

    #termsAndConditions::-webkit-scrollbar-thumb {
        background: #636466;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #444444;
    }
.submitSection {
    padding: 20px 5px;
    text-align: right;
}

h1, h2, h3, h4 {
    font-weight: bold;
}

h1, h3, h4 {
    color: #FEC10D;
}

h1 {
    font-size: 40px;
    padding-top: 75px;
    padding-bottom: 50px;
}

    h2 {
        font-size: 30px;
    }

    h3 {
        font-size: 18px;
    }

@media(min-width: 768px) {
    h1 {
        font-size: 60px;
    }

    h2 {
        font-size: 30px;
    }

    h3 {
        font-size: 18px;
    }
}

@media(min-width: 1200px) {
    h1{
    font-size: 85px;
    }
    h2 {
        font-size: 40px;
    }
    h3 {
        font-size: 30px;
    }
    
}

label{
    font-weight: bold;
    font-size: 18px/23px;
}

.btn-primary {
    height: 48px;
    width: auto;
    padding: 10px 15px;
    border-radius: 3px;
    color: #fff;
    background-color: #FEC10D;
    border-color: #FEC10D;
}
